// GLOBAL STARTER DEPENDENCIES
import $ from 'jquery'
import 'tether'
import 'bootstrap'
import 'timepicker'
import 'bootstrap-datepicker'
import 'holderjs'
import 'axios'

import 'flickity-imagesloaded'
import 'flickity'

// import Flickity from 'flickity'

import 'moment'
import 'vue'
export {Slider, CraftVue} from './starters'


// PROJECT SPECIFIC DEPENDENCIES




// ****************************************************************************
// GLOBAL JAVASCRIPT (COMPILES INTO SCRIPTS-BUNDLE.JS AND SERVED ON EVERY PAGE)
// ****************************************************************************
